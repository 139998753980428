//@ts-nocheck

import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { scrollTo } from "../../util";
import { useOnClickOutside } from "usehooks-ts";
import IconButton from '../common/IconButton';

const menus = [
	{ id: "dead_wallet", name: "Dead wallet" },
	{ id: "flash_recharge", name: "Flash Recharge" },
	{ id: "services", name: "All Services" },
	{ id: "flash_transfer", name: "Flash Transfer" },
	{ id: "here_partners", name: "Partners" },
]

const Header2 = () => {
	const [showMobileMenu, setShowMobileMenu] = useState(false);

	const btnRef = useRef(null);
	const menuRef = useRef(null);
	useOnClickOutside([btnRef, menuRef], (e) => {
		setShowMobileMenu(false);
	});

	const topScroll = (id: any) => {
		scrollTo({ id });
		setShowMobileMenu(false);
	}
	return (
		<header className='w-full z-50 fixed top-0 left-0 py-[30px] px-[20px] md:py-[47px] bg-[#0F0F0F]'>
			<div className='max-w-full w-[1520px] mx-auto flex items-center justify-between relative gap-4'>
				<Link to='/' className='flex flex-row gap-2 items-center'>
					<img src="/logo2.png" alt="" className="w-[50px] h-[50px]" />
					<span className='text-[22px] font-semibold hidden md:block text-nowrap'>
						Flash <span className='primary-color'>Wallet</span>
					</span>
				</Link>

				<ul className='hidden md:flex flex-row gap-4 2xl:gap-8'>
					{menus.map(item =>
						<li key={item.id} className='text-center font-normal text-[18px] text-[#E7E7E9] hover:text-[#FFC000] cursor-pointer' onClick={() => topScroll(item.id)}>
							{item.name}
						</li>
					)}
				</ul>

				<IconButton
					icon={"/assets/images/home/download.svg"}
					text='Download App'
					className='hidden xl:flex min-w-[188px]'
					onClick={() => window.open("https://onelink.to/hekxaq", "_blank")}
				/>

				<div ref={btnRef}
					className="md:hidden w-[44px] h-[44px] rounded-full flex items-center justify-center bg-[#181818]"
					onClick={() => setShowMobileMenu(!showMobileMenu)}
				>
					<img src="/assets/images/hamburger.png" alt="" />
				</div>

				{showMobileMenu &&
					<div
						ref={menuRef}
						className='bg-[#1B1B1B] rounded-[16px] w-[300px] p-4 flex flex-col gap-4 z-50 absolute right-4 top-[60px] shadow-[0_19px_12px_0px_#00000040]'
					>
						{menus.map(item =>
							<div key={item.id}
								className='font-normal text-[#E7E7E9] text-[18px] p-4 border-b border-[#212121]'
								onClick={() => topScroll(item.id)}
							>
								{item.name}
							</div>
						)}
						<IconButton
							icon={"/assets/images/home/download.svg"}
							text='Download App'
							className='w-full'
							onClick={() => window.open("https://onelink.to/hekxaq", "_blank")}
						/>
					</div>
				}
			</div>
		</header>
	);
};

export default Header2;
