//@ts-nocheck

import React from 'react';
import TitleBox from '../common/TitleBox';
import IconButton2 from '../common/IconButton2';

const RechargeCard = () => {
	return (
		<div id="flash_recharge" className='max-w-full w-[1600px] px-[25px] pb-[100px] flex flex-col lg:flex-row justify-between mx-auto'>
			<img src='/assets/images/home/flash-cards.png' alt="dead" className='w-full lg:max-w-[50%] mr-[-20px] z-[1]' />

			<div className='w-full px-[20px] pt-[24px] lg:px-0 lg:pt-0'>
				<div className='w-full flex flex-col items-start justify-center bg-[#181818] rounded-[40px] p-[20px] md:px-[73px] md:py-[96px]'>
					<TitleBox text='Top up & spend' className='w-fit' />
					<div className='text-[32px] lg:text-[80px] text-start font-[500] text-white mt-[20px] lg:leading-[92px]'>
						Recharge &<br /> Spend with<br /> Flash Wallet!
					</div>
					<div className='text-[14px] lg:text-[20px] lg:max-w-[596px] text-start font-[400] text-[#B3B3B3] mt-[12px] lg:mt-[30px] lg:leading-[32px]'>
						Recharge your account with our prepaid Flash recharge cards & spend your crypto assets with your integrated virtual Flash card !
					</div>
					<div className='text-[14px] lg:text-[20px] lg:max-w-[596px] text-start font-[400] text-[#B3B3B3] mt-[12px] lg:mt-[24px] lg:leading-[32px]'>
						Top up your non-custodial Flash Wallet, world exclusive, directly with a prepaid card available from our partner merchants or on the e-shop ! This avoids going through an exchange and provides access to crypto in many countries that only use cash !
					</div>
					<div className='mt-[20px] md:mt-[46px] w-full flex flex-col justify-start md:flex-row gap-8'>
						<IconButton2 icon='/assets/images/home/google-play.svg' className='w-full md:w-fit'
							onClick={() => window.open("https://play.google.com/store/apps/details?id=com.flashwallet.production", "_blank")}
						/>
						<IconButton2 icon='/assets/images/home/app-store.svg' className='w-full md:w-fit'
							onClick={() => window.open("https://apps.apple.com/us/app/flash-wallet/id6461013442", "_blank")}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RechargeCard;
