//@ts-nocheck

import React from 'react';


const services = [
	{
		title: 'Flash Transfer Exchange',
		img: 'ico-exchange.svg',
		content: 'Buy, sell, transfer, directly from Flash Transfer partner merchants with Flash Wallet !'
	},
	{
		title: 'Dead Wallet - Decentralized Write Testament for your heiress',
		img: 'ico-dead.svg',
		content: 'Define your crypto and NFT legacy with gas fee insurance. Cancel anytime for full control.'
	},
	{
		title: 'Flash Recharge',
		img: 'ico-recharge.svg',
		content: 'Top up your exclusive non-custodial Flash Wallet directly with a prepaid card from partner merchants or our e-shop.'
	},
	{
		title: 'Flash Virtual Card',
		img: 'ico-vcard.svg',
		content: 'Spend your crypto directly on virtual card in Flash wallet ! You can pay directly with Google Wallet or Apple Pay.'
	},
	{
		title: 'Flash Launch',
		img: 'ico-launch.svg',
		content: "Use our world's most secure direct built-in swap!"
	},
	{
		title: 'Flash Wallet Multi-Chain',
		img: 'ico-multi.svg',
		content: 'Get a complete view of your active cryptos (Tokens and NFTs) on your wallet.'
	},
	{
		title: 'Flash Staking',
		img: 'ico-stake.svg',
		content: 'Access attractive rates, without ponzi earn for our ecosystem'
	},
	{
		title: 'Flash DEX',
		img: 'ico-dex.svg',
		content: 'Bridge tokens from one chain to another quickly, easily and cheaply'
	},
	{
		title: 'Flash VPN',
		img: 'ico-vpn.svg',
		content: 'Protect your privacy with a built-in VPN directly in Flash Wallet'
	},
	{
		title: 'Flash Browser',
		img: 'ico-browser.svg',
		content: 'Browse safely and interact with any Dapps in our browser. Earn Flash using your desktop browser in a future version.'
	},
	{
		title: 'Flash File Storage',
		img: 'ico-storage.svg',
		content: 'Store your data on a decentralized cloud via the blockchain which guarantees the best security, anonymity and transparency'
	},
	{
		title: 'Flash NFT Marketpalce',
		img: 'ico-nft.svg',
		content: 'Find all the popular marketplaces on a single marketplace like Opensea, Rarible amd more.'
	},
];


const Services = () => {
	return (
		<div id="services" className='max-w-full w-[1600px] px-[25px] pb-[100px] flex flex-col items-center mx-auto'>
			<div className='text-[32px] lg:text-[80px] text-start w-full font-[500] text-white mt-[20px] lg:leading-[92px]'>
				All-in-One DeFi <br />Services with Flash Wallet
			</div>
			<div className='mt-[32px] lg:mt-[64px] w-full gap-[24px] lg:gap-[40px] grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3'>
				{
					services.map((item, index) =>
						<div key={index}>
							<div className='relative p-[25px] md:p-[34px] pt-[90px] md:pt-[130px] w-[334px] h-[212px] md:w-[480px] md:h-[304px] mx-auto hover:scale-110 transition-all duration-500'
								style={{ background: "url(/assets/images/home/bg-card.png) center no-repeat", backgroundSize: "contain" }}
							>
								<img
									src={`/assets/images/home/${item.img}`} alt=""
									className='absolute w-[16px] h-[16px] md:w-auto md:h-auto top-[51px] left-[51px] md:top-[73px] md:left-[73px] -translate-x-1/2 -translate-y-1/2'
								/>
								<div className='text-white font-[500] text-[16px] md:text-[24px] leading-[20px] md:leading-[30px]'>{item.title}</div>
								<div className='text-[#B3B3B3] font-[400] text-[14px] md:text-[18px] leading-[22px] md:leading-[32px] mt-[8px] md:mt-[12px]'>{item.content}</div>
							</div>
						</div>
					)
				}
			</div>
		</div>
	);
};

export default Services;
