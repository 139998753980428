

const IconButton2 = ({
  icon = "",
  className = "",
  onClick = () => { }
}) => {
  return (
    <div className={`bg-gradient-to-b from-[#474747] to-[#1F1F1F] p-[2px] rounded-[16px] cursor-pointer ${className}`} onClick={onClick}>
      <div
        className='flex flex-row items-center justify-center gap-3 px-8 py-3 rounded-[16px] bg-gradient-to-b hover:bg-gradient-to-t from-[#282828] to-[#1F1F1F]'
      >
        <img src={icon} alt="icon" />
      </div>
    </div>
  );
};

export default IconButton2;
