/* import React from 'react'; */
import Header2 from '../header/header2';
import Footer2 from '../footer/footer2';

const Layout2 = (props: any) => {
    return (<>
        <main className=" overflow-x-hidden bg-[#0F0F0F]">
            <Header2 />
            {props.children}
            <Footer2 />
        </main>
    </>);
}

export default Layout2;