//@ts-nocheck

import React from 'react';
import TitleBox from '../common/TitleBox';
import IconButton2 from '../common/IconButton2';
import InfoCard from '../common/InfoCard';
import { useMediaQuery } from 'react-responsive';

const Banner = () => {
	const isMobile = useMediaQuery({ maxWidth: 768 });

	return (
		<div className='w-full mt-[160px] pb-[100px] flex flex-col items-center'>
			<div className='max-w-full w-[1720px] px-[25px]'>
				<div className='w-full flex flex-col items-center'
					style={{ background: isMobile ? "transparent" : "url(/assets/images/home/bg-grid4.png) center top repeat-x" }}
				>
					<div className='flex flex-col items-center w-fit rounded-full md:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#1F1F1F] from-25% to-[#00000000] to-50%'>
						<TitleBox text='Effortless, Secure, Multi-Chain' className='w-fit' />
						<div className='text-[40px] lg:text-[100px] text-center font-[500] text-white mt-[20px] lg:leading-[116px]'>
							Simplify DeFi<br />With Flash Wallet
						</div>
						<div className='text-[14px] lg:text-[20px] text-center font-[400] text-[#B3B3B3] mt-[12px] lg:mt-[30px] lg:leading-[32px]'>
							We simplify DeFi for easy access and full control<br />your keys, your crypto!
						</div>
						<div className='mt-[20px] md:mt-[46px] w-full flex flex-col justify-center md:flex-row gap-8 z-10'>
							<IconButton2 icon='/assets/images/home/google-play.svg' className='w-full md:w-fit'
								onClick={() => window.open("https://play.google.com/store/apps/details?id=com.flashwallet.production", "_blank")}
							/>
							<IconButton2 icon='/assets/images/home/app-store.svg' className='w-full md:w-fit'
								onClick={() => window.open("https://apps.apple.com/us/app/flash-wallet/id6461013442", "_blank")}
							/>
						</div>
					</div>
				</div>
				<div
					className='relative w-full h-[350px] sm:h-[500px] md:h-[1100px]'
					style={{ background: isMobile ? "url(/assets/images/home/bg-grid3.png)" : "url(/assets/images/home/bg-grid2.png) center 100px repeat-x" }}
				>
					<img src='/assets/images/home/phone1.png' alt="phon1" className='absolute w-[258px] sm:w-[400px] md:w-auto left-[-10px] md:left-auto md:right-[50%] top-[20px] md:top-[-80px] md:mr-[-240px]' />
					<img src='/assets/images/home/phone2.png' alt="phon2" className='absolute w-[258px] sm:w-[400px] md:w-auto right-[-30px] md:right-auto md:left-[50%] top-[20px] md:top-[-60px] md:ml-[-120px]' />
				</div>
				<div className='w-full flex flex-row justify-center items-center gap-4 md:gap-10 flex-wrap'>
					<InfoCard label='Total Volume' value='$1M+' />
					<InfoCard label='Wallets Connected' value='10K+' />
					<InfoCard label='Total Transactions' value='100K+' />
					<InfoCard label='Supported Chains' value='EVM' />
				</div>
			</div>
		</div>
	);
};

export default Banner;
