import { useState, useEffect } from "react";

import Layout2 from "../../component/layout/layout2";
import Banner from "../../component/home/Banner";
import DeadWallet from "../../component/home/DeadWallet";
import RechargeCard from "../../component/home/RechargeCard";
import MultiChain from "../../component/home/MultiChain";
import Services from "../../component/home/Services";
import FlashTransfer from "../../component/home/FlashTransfer";
import DefiWallet from "../../component/home/DefiWallet";
import Partners from "../../component/home/Partners";
import "./home.scss";

const Home2 = () => {

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 5000)
	}, [])

	return (
		<>
			{/* {isLoading &&
				<div className={`isloading`}>
					<div className='loading-img flex items-center justify-center'>
						<img src='/logo2.png' className='w-[70px]' alt='' />
						<span className="text-[30px] font-bold text-[#e7c766]">FLASH</span>
					</div>
				</div>
			} */}
			<Layout2>
				<Banner />
				<DeadWallet />
				<RechargeCard />
				<MultiChain />
				<Services />
				<FlashTransfer />
				<DefiWallet />
				<Partners />
			</Layout2>
		</>
	);
};
export default Home2;
