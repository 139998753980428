

const InfoCard = ({
  label = "",
  value = "",
  className = "",
  wrapperClassName = "",
}) => {
  return (
    <div className={`bg-gradient-to-b from-[#2D2D2D] to-[#2D2D2D00] p-[2px] rounded-[9px] md:rounded-[22px] ${wrapperClassName}`}>
      <div
        className={`flex flex-col items-center justify-center gap-2 px-5 py-3 rounded-[9px] md:rounded-[22px] bg-[#181818] w-[157px] h-[90px] md:w-[350px] md:h-[200px] ${className}`}
      >
        <div className="text-white font-[400] text-[9px] md:text-[20px]">{label}</div>
        <div className="text-white font-[700] text-[23px] md:text-[52px]">{value}</div>
      </div>
    </div>
  );
};

export default InfoCard;
