//@ts-nocheck

import React from 'react';
import TitleBox from '../common/TitleBox';

const chainlogos = [
	{
		name: 'Ethereum',
		img: 'ether.svg'
	},
	{
		name: 'BNB smart <br/> chain',
		img: 'binance2.svg'
	},
	{
		name: 'Polygon',
		img: 'polygon.svg'
	},
	{
		name: 'Fantom',
		img: 'fantom2.svg'
	},
	{
		name: 'Avalanche',
		img: 'avalanche.svg'
	},
	{
		name: 'Cronos',
		img: 'cronos2.svg'
	},
	{
		name: 'Arbitrum',
		img: 'arbitrum.svg'
	},
	{
		name: 'Bitcoin',
		img: 'btc.svg'
	},
	{
		name: 'Base',
		img: 'base.svg'
	},
	{
		name: 'Solana',
		img: 'solana.svg'
	},
	{
		name: 'Optimism',
		img: 'optimism.svg'
	},
];


const MultiChain = () => {
	return (
		<div className='max-w-full w-[1600px] px-[25px] pb-[100px] flex flex-col items-center mx-auto'>
			<TitleBox text='Supported all EVM Chains and more' className='w-fit' />
			<div className='text-[32px] lg:text-[80px] text-center font-[500] text-white mt-[20px] lg:leading-[92px]'>
				The only Multi-Chain <br />Flash wallet you need for be free
			</div>
			<img src='/assets/images/home/horz-splitter.png' className='mt-[48px]' alt='' />
			<div className='mt-[20px] w-full lg:min-h-[128px] flex flex-row flex-wrap justify-center items-center gap-[16px] lg:gap-[40px]'
				style={{ background: "url(/assets/images/home/bg-grid.png) center" }}
			>
				{
					chainlogos.map((item, index) => (
						<div className=" text-center hover:scale-125 transition-all duration-500" key={index}>
							<img src={"/assets/images/landing/multichain/" + item.img} alt="" className={`w-[25px] h-[25px] lg:w-[60px] lg:h-[60px] rounded-full m-auto cursor-pointer`} />
						</div>
					))
				}
			</div>
			<img src='/assets/images/home/horz-splitter.png' className='mt-[20px]' alt='' />
		</div>
	);
};

export default MultiChain;
