//@ts-nocheck

import React, { useState } from 'react';
import TitleBox from '../common/TitleBox';
import IconButton from '../common/IconButton';

const DeadWallet = () => {
	const [isPlaying, setIsPlaying] = useState(false);

	return (
		<div id="dead_wallet" className='max-w-full w-[1600px] px-[25px] pb-[100px] flex flex-col md:flex-row-reverse justify-between gap-8 mx-auto'>
			<div className='w-full md:max-w-[50%] flex justify-center items-center'>
				{isPlaying ?
					<iframe title="video" class="w-full aspect-video " src="https://www.youtube.com/embed/_QEIvJ8aqMM?autoplay=0"></iframe>
					:
					<img src='/assets/images/home/dead.png' alt="dead" className='w-full' />
				}
			</div>

			<div className='w-full flex flex-col items-center md:items-start justify-center'>
				<TitleBox text='Dead Wallet' className='w-fit' />
				<div className='text-[32px] lg:text-[80px] text-center md:text-start font-[500] text-white mt-[20px] lg:leading-[92px]'>
					Flash Wallet,<br />Dead function
				</div>
				<div className='text-[14px] lg:text-[20px] lg:max-w-[530px] text-center lg:text-start font-[400] text-[#B3B3B3] mt-[12px] lg:mt-[30px] lg:leading-[32px]'>
					First decentralized function in the world that allows you to send your crypto assets in the event of death !
				</div>
				<div className='text-[14px] lg:text-[20px] lg:max-w-[620px] text-center lg:text-start font-[400] text-[#B3B3B3] mt-[12px] lg:mt-[24px] lg:leading-[32px]'>
					World’s first decentralized feature to send crypto assets in case of death. Select assets, timing, and destination with gas fee insurance included. Cancel anytime and set to EVM-compatible wallets or non-custodial addresses for added security.
				</div>
				<div className='mt-[20px] md:mt-[46px] w-full flex flex-col justify-start md:flex-row gap-8'>
					<IconButton
						icon={"/assets/images/home/download.svg"}
						text='Download Flash Wallet'
						onClick={() => window.open("https://onelink.to/hekxaq", "_blank")}
					/>
					<IconButton
						BgClass='bg-[#E7B10B1C]'
						BgHoverClass='hover:bg-[#E7B10B2C]'
						TxClass='text-[#E7B10B]'
						className='border border-[#E7B10B99]'
						icon={"/assets/images/home/video-play.svg"}
						text='Watch Video'
						onClick={() => setIsPlaying(true)}
					/>
				</div>
			</div>
		</div>
	);
};

export default DeadWallet;
