//@ts-nocheck

import React from 'react';
import TitleBox from '../common/TitleBox';
import IconButton from '../common/IconButton';

const FlashTransfer = () => {
	return (
		<div id="flash_transfer" className='max-w-full w-[1600px] px-[25px] pb-[100px] flex flex-col md:flex-row-reverse items-stretch justify-between mx-auto gap-[40px]'>
			<div className='w-full md:max-w-[50%] flex justify-center items-center'>
				<img src='/assets/images/home/wallet-transfer.png' alt="wallet-transfer" className='w-full' />
			</div>

			<div className='w-full flex flex-col items-start justify-center bg-[#181818] rounded-[16px] lg:rounded-[40px] p-[20px] lg:px-[73px] lg:py-[100px]'>
				<TitleBox text='Flash Merchants partners' className='w-fit' />
				<div className='text-[32px] lg:text-[80px] text-start font-[500] text-white mt-[20px] lg:leading-[92px]'>
					Flash Transfer<br />directly on your<br />Flash Wallet !
				</div>
				<div className='text-[14px] lg:text-[20px] md:max-w-[596px] text-start font-[400] text-[#B3B3B3] mt-[12px] md:mt-[28px] lg:leading-[32px]'>
					With Flash Wallet, manage your crypto assets effortlessly. Buy, sell, and transfer directly through Flash Transfer's partner merchants. Enjoy seamless transactions and secure exchanges all in one place!
				</div>
				<div className='mt-[16px] md:mt-[30px] w-full flex flex-col justify-start md:flex-row gap-8'>
					<IconButton text='Join Flash Revolution' className='w-full md:w-[237px]' />
				</div>
			</div>
		</div>
	);
};

export default FlashTransfer;
