//@ts-nocheck

import React from 'react';
import { useMediaQuery } from 'react-responsive';


const partners = [
	{
		img: 'binance2.png',
		bg: 1,
		bg2: 1,
	},
	{
		img: 'coinbase.png',
		bg: 2,
		bg2: 3,
		pos2: "bottom left"
	},
	{
		img: 'microsoft.png',
		bg: 2,
		bg2: 1,
	},
	{
		img: 'dexscreener.png',
		bg: 3,
		bg2: 3,
		pos2: "bottom left"
	},
	{
		img: 'ledger2.png',
		bg: 6,
		bg2: 1,
	},
	{
		img: 'onramper.png',
		bg: 6,
		bg2: 3,
		pos2: "bottom left"
	},
	{
		img: 'coinmarketcap.png',
		bg: 6,
		bg2: 6,
		pos2: "top right"
	},
	{
		img: 'coingecko.png',
		bg: 0,
		bg2: 0,
	},
];


const Partners = () => {
	const isMobile = useMediaQuery({ maxWidth: 1024 });

	return (
		<div id="here_partners" className='max-w-full w-[1600px] px-[25px] pb-[100px] flex flex-col items-center mx-auto'>
			<div className='text-[32px] lg:text-[80px] text-center md:text-start w-full font-[500] text-white mt-[20px] lg:leading-[92px]'>
				Our Trusted Partners
			</div>
			<div className='mt-[32px] lg:mt-[64px] w-full grid grid-cols-2 lg:grid-cols-4 gap-1 md:gap-0'>
				{
					partners.map((item, index) =>
						<div key={index} style={{ background: `url(/assets/images/home/bg-partner-${isMobile ? item.bg2 : item.bg}.svg) ${(isMobile && item.pos2) ? item.pos2 : 'bottom right'} no-repeat` }}>
							<div className='flex justify-center items-center max-w-full w-[160px] h-[130px] md:w-[387px] md:h-[178px] mx-auto'>
								<img src={`/assets/images/landing/site-partners/${item.img}`} alt=""
									className='max-w-[120px] md:max-w-max cursor-pointer hover:scale-125 transition-all duration-500 -ms-4 -mt-4'
								/>
							</div>
						</div>
					)
				}
			</div>
		</div>
	);
};

export default Partners;
