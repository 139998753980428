//@ts-nocheck

import React from 'react';
import TitleBox from '../common/TitleBox';
import IconButton2 from '../common/IconButton2';

const DefiWallet = () => {
	return (
		<div className='relative'>
			<img src='/assets/images/home/bg-grid4.png' alt='' className='w-full absolute bottom-[300px] lg:bottom-auto lg:top-[300px] z-0' />
			<img src='/assets/images/home/bg-grid4.png' alt='' className='w-full absolute bottom-[150px] lg:bottom-[250px] z-0' />
			<div className='max-w-full w-[1720px] px-[25px] pt-0 md:pt-[80px] pb-[100px] flex flex-col items-center mx-auto z-10'>
				<TitleBox text='Download Mobile App' className='w-fit' />
				<div className='text-[32px] lg:text-[80px] max-w-[1190px] text-center font-[500] text-white mt-[20px] lg:leading-[92px]'>
					The revolution of DEFI wallets before your eyes!
				</div>
				<div className='text-[14px] lg:text-[20px] text-center font-[400] text-[#B3B3B3] mt-[12px] md:mt-[20px] lg:leading-[32px]'>
					We simplify DeFi for easy access and full control<br />your keys, your crypto!
				</div>
				<div className='mt-[20px] md:mt-[46px] w-full flex flex-col justify-center md:flex-row gap-8'>
					<IconButton2 icon='/assets/images/home/google-play.svg' className='w-full md:w-fit z-10'
						onClick={() => window.open("https://play.google.com/store/apps/details?id=com.flashwallet.production", "_blank")}
					/>
					<IconButton2 icon='/assets/images/home/app-store.svg' className='w-full md:w-fit z-10'
						onClick={() => window.open("https://apps.apple.com/us/app/flash-wallet/id6461013442", "_blank")}
					/>
				</div>
				<img src='/assets/images/home/phone3.png' alt='' />
				<img src='/assets/images/home/horz-splitter-lg.png' alt='' className='mt-0 md:mt-[60px]' />
			</div>
		</div>
	);
};

export default DefiWallet;
